@import "variables.css";
@import "main.css";
@import "header.css";
@import "footer.css";
@import "homeslider.css";
@import "topnav.css";
@import "cards.css";
@import "cards-homepage.css";
@import "stickers.css";
@import "col-xxl.css";
@import "article.css";
@import "news.css";
@import "btn-primary.css";
@import "contacts.css";
@import "tooltips.css";
@import "tables.css";


.link-inline, .link-inline:hover {
  text-decoration: underline;
  cursor: pointer;
  font: var(--font-links);
  color: var(--color-blue-dark);
  font-size: inherit;
}
.link-inline:focus {
  outline: 0;
}

.link-inline-disabled, .link-inline-disabled:hover {
  font: var(--font-links);
  color: var(--color-blue-dark);
  font-size: inherit;
}
.link-inline-disabled:focus {
  outline: 0;
}


@media screen and (min-width: 100px) 
{ 
  .visible-for-wide-block {
    display: none;
  }

  .visible-for-wide-flex {
    display: none;
  }

  .visible-for-mobile-inner {
    display: inner;
  }

  .visible-for-mobile-block {
    display: block;
  }

  .keep-width-over900 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .keep-width-over1200 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .holder-paddings {
    padding: 20px 10px 10px 10px;
  }
}

@media screen and (min-width: 1000px) {
  .visible-for-wide-block {
    display: block;
  }

  .visible-for-wide-flex {
    display: flex;
  }

  .visible-for-mobile-inner {
    display: none;
  }

  .visible-for-mobile-block {
    display: none;
  }

  .keep-width-over900 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .keep-width-over1200 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .holder-paddings {
    padding: 40px 40px 40px 40px;
  }
}

@media screen and (min-width: 1220px) { .keep-width-over1200 { min-width:1200px; max-width:1200px; width:1200px;} }
@media screen and (min-width: 920px) { .keep-width-over900 { min-width:900px; max-width:900px; width:900px;} }

.quote {  
  margin: 7px 10px 7px 15px;
  font: var(--font-quote);
}

.anchor {
  visibility: hidden; 
  height:0px;
  position:relative; 
  top:-210px; 
  left:0px;
}

.filter-active {
  background-color: var(--color-blue-dark)!important;
  border-color: var(--color-blue-dark)!important;
  color: var(--color-yellow-light)!important;
  font: var(--font-links)!important;
  font-size: 1.1rem!important;
  outline: none!important;
  box-shadow: none!important;
  transition: background-color 0.5s ease;
}

.color-bl-gr {
  color:var(--color-blue-grayed);
}
.color-bl-me {
  color:var(--color-blue-medium);
}
.color-bl-da {
  color:var(--color-blue-dark);
}