header {
  position: sticky; 
  top: 0px; 
  position: -webkit-sticky;
  z-index: 1000;
}

@media screen and (max-height: 400px) {
  header {
    position: static; 
  }
}

.content-wrapping {
  position: relative;
}

.img-fluid {
  max-width: 100%; 
  height: auto;
}

.img-anchor-right {
 height:200px; 
 transition: height 1s ease; 
 object-fit: cover; 
 object-position: right top;
}

@media screen and (min-width: 820px) 
{
  .img-anchor-right-for-captive {
    height:160px;
    transition: height 1s ease; 
  }
}

.pos-head-row-1 {
  position: absolute; 
  top: 8px;
  left:10px;
}

.pos-head-row-2 {
  position: absolute; 
  top: 165px;
  left:127px;
  width:705px;
  transition: top 1s ease; 

}

.visible-for-height-high {
 display: block;
}
.visible-for-height-low {
 display: none;
}

.img-logo {
  display: block;
  cursor:pointer;
}

.head-info-position {
  position:absolute;
  top:165px;
  transition: top 1s ease; 
}

/* ������ � ������� �������� ������ ����� ��� ������� */
@media screen and (max-height: 1000px) {
  .img-anchor-right-thenpagescrolled {
    height:100px;
    transition: height 1s ease; 
  }
  .pos-head-row-2-thenpagescrolled {
    top: 65px;
    transition: top 1s ease; 
  }
  .head-info-position-thenpagescrolled {
    top:63px;
    transition: top 1s ease; 
  }

  .visible-for-height-high-thenpagescrolled {
    display: none;
  }
  .visible-for-height-low-thenpagescrolled {
    display: block;
  }
}

/* ������ � ������� �������� ������ ����� ��� ������� */
@media screen and (max-height: 1000px) and (max-width: 699px) {
    .img-logo-hide {
      visibility:hidden;
    }
}

.img-logo:focus {
  outline: 0;
}


.font-head {
  font: var(--font-links);
  font-weight: bold;
  color: var(--color-whitewarm);
}

.image-holder {
  padding-right: 20px;
  padding-left: 5px;
}

@media screen and (min-width: 100px) 
{ 
  .img-logo {
    height:40px;
    max-width:80px;
    max-height:40px;
    min-width:80px;
    min-height:40px;
    margin-top:3px;  
    padding-left: 0px;
  }
  .font-head {
    font-weight: normal;
  }

  .visible-hdr-for-wide-block {
    display: none;
  }

  .visible-hdr-for-mobile-inner {
    display: inner;
  }
}

@media screen and (min-width: 820px) {
  .img-logo {
    height:60px;
    max-width:120px;
    max-height:60px;
    min-width:120px;
    min-height:60px;
    margin-top:5px;  
  }

  .font-head {
    font-weight: bold;
  }

  .visible-hdr-for-wide-block {
    display: block;
  }

  .visible-hdr-for-mobile-inner {
    display: none;
  }
}

@media screen and (min-width: 100px) { .font-size-head-main { font-size: 1.2rem; } .font-size-head-info { font-size: 0.72rem; }}
@media screen and (min-width: 365px) { .font-size-head-main { font-size: 1.3rem; } .font-size-head-info { font-size: 0.72rem; }}
@media screen and (min-width: 620px) { .font-size-head-main { font-size: 1.5rem; } .font-size-head-info { font-size: 0.72rem; }}
@media screen and (min-width: 900px) { .font-size-head-main { font-size: 1.6rem; } .font-size-head-info { font-size: 0.8rem; }}
@media screen and (min-width: 980px) { .font-size-head-main { font-size: 1.8rem; } .font-size-head-info { font-size: 0.88rem; }}
@media screen and (min-width: 1120px) { .font-size-head-main { font-size: 2rem; } .font-size-head-info { font-size: 1.0rem; }}
@media screen and (min-width: 1240px) { .font-size-head-main { font-size: 2.2rem; } .font-size-head-info { font-size: 1.16rem; }}
@media screen and (min-width: 1300px) { .font-size-head-main { font-size: 2.3rem; } .font-size-head-info { font-size: 1.2rem; }}
