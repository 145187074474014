.topnav {
  overflow: hidden;
}

.topnav a {
  float: left;
  color: var(--color-whitewarm);
  text-align: center;
  padding-left: 30px;
  padding-top: 5px;
  text-decoration: none;
  font: var(--font-links);
}

.topnav a:hover {
  text-decoration: overline;
}

.topnav a.active {
  text-decoration: overline;
  color: var(--color-yellow-medium);
}
