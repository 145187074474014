.article-header {
  font: var(--font-header);
  font-weight:600;
  color: var(--color-blue-dark);
}

.article-holder h2 {
  font: var(--font-header);
  font-weight:600;
  color: var(--color-blue-dark);
}

.article-text {
  font: var(--font-text-long);
  text-align: justify;
}

.article-holder {
  height: 100%; 
  border-radius: 20px 10px 10px 10px; 
  border: solid 1px #CCCCCC;
}

.section-header {
  font: var(--font-header1);
  
  /* background: linear-gradient(0deg, var(--color-whitewarm), #88A8B8); */
  background: linear-gradient(var(--color-gray), #F8FAF2 90%, var(--color-whitewarm));
  font-weight:600;
  color: var(--color-blue-dark);
  text-align: center;
}

.section-header1 {
  background: linear-gradient(var(--color-gray), #F8FAF2 90%, var(--color-whitewarm));
  min-height: 100px; 
}

.article-holder h3 {
  font: var(--font-header);
  font-weight:600;
  color: var(--color-blue-dark);
  margin-bottom:0rem;
}

.article-holder *.p3 {
  font: var(--font-text);
}

.article-holder div.p3 li {
    margin-bottom:0.5rem;
}

.article-holder h4 {
  font: var(--font-header);
  font-weight:600;
  color: var(--color-blue-dark);
}

.article-holder *.p4 {
  font: var(--font-text);
  line-height:1.2rem;
}

.article-holder div.p4 li {
    margin-bottom:0.3rem;
}

.article-holder div.article-text li {
    margin-bottom:0.3rem;
}

.article-holder .image-link {
  border: solid 2px transparent;
  opacity:1;
}

.article-holder .image-link img {
  border: solid 2px transparent;
}

.article-holder .image-link img:hover {
  border: solid 2px var(--color-yellow-medium);
  opacity:0.9;
}

@media screen and (min-width: 100px) 
{ 
  .article-header {
    font-size: 1.5rem;
    text-indent:1.7rem;
  }

  .article-holder h2 {
    font-size: 1.5rem;
    text-indent:1.7rem;
  }


  .article-text {
    font-size: 0.8rem;
    line-height:1.5rem;
    text-indent:1.7rem;
  }

  .section-header {
    min-height: 60px; 
    height:60px; 
    font-size: 1.3rem;
  }

  .section-header h1 {
    font-size: 2.5rem;
  }

  .article-holder h3 {
    font-size: 1.0rem;
  }

  .article-holder *.p3 {
    font-size: 1.0rem;
    margin-bottom:0.3rem;
    margin-top:0.5rem;
  }

  .article-holder h4 {
    font-size: 0.9rem;
    margin-top: 0.8rem;
  }

  .article-holder *.p4 {
    font-size: 0.9rem;
    margin-bottom:0.3rem;
  }

  .article-holder div.article-text li {
    font-size: 0.8rem;
    line-height:1.2rem;
    text-indent:0rem;
    margin-left:1.5rem;
  }

}

@media screen and (min-width: 1000px) 
{ 
  .article-header {
    font-size: 1.7rem;
    text-indent:2rem;
  }

  .article-holder h2 {
    font-size: 1.7rem;
    text-indent:2rem;
  }

  .article-text {
    font-size: 1rem;
    line-height:1.7rem;
    text-indent:2rem;
  }

  .section-header {
    min-height: 100px; 
    height:100px; 
    font-size: 1.3rem;
  }

  .section-header h1 {
    font-size: 3.5rem;
  }

  .article-holder h3 {
    font-size: 1.3rem;
  }

  .article-holder *.p3 {
    font-size: 1.3rem;
    margin-bottom:0.5rem;
    margin-top:0.7rem;
  }

  .article-holder h4 {
    font-size: 1.1rem;
    margin-top:1rem;
  }

  .article-holder *.p4 {
    font-size: 1.1rem;
    margin-bottom:0.5rem;
  }

  .article-holder div.article-text li {
    font-size: 1rem;
    line-height:1.4rem;
    text-indent:0rem;
    margin-left:2rem;
  }

}
