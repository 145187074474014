.carousel-inner {
  cursor:pointer;
}
.carousel-control-prev,
.carousel-control-next{
  width: 5vw;
}
.carousel-control-prev:hover,
.carousel-control-next:hover{
  background-color: var(--color-blue-medium-transp);
}

.carousel-control-prev-icon { 
  background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230080C0' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); 
  width: 30px;
  height: 48px;
}

.carousel-control-next-icon { 
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230080C0' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  width: 30px;
  height: 48px;
}    

.carousel-indicators {
  margin-bottom: 5px;
}

.carousel-indicators li {
  height: 10px;
  width: 10px;
  border-radius: 0%;
  margin-left: 10px;
  margin-right: 10px;
  background: var(--color-blue-light);
  border:solid 1px var(--color-blue-dark);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); 
}

.carousel-indicators li.active {
  background: var(--color-whitecold);
  width: 14px;
  height: 14px;
  margin-top: -2px;
  margin-left: 8px;
  margin-right: 8px;
}

.carousel-indicators li:hover
{
  background: var(--color-blue-medium);
}