 .col-xxl-1,.col-xxl-2,.col-xxl-3,.col-xxl-4,.col-xxl-5,.col-xxl-6,.col-xxl-7,.col-xxl-8,.col-xxl-9,.col-xxl-10,.col-xxl-11,.col-xxl-12,.col-xxl,.col-xxl-auto
   {
      position:relative;
      width:100%;
      padding-right:15px;
      padding-left:15px
    }

  @media (min-width: 1600px)
  {
    .col-xxl{flex-basis:0;flex-grow:1;max-width:100%}
    .col-xxl-auto{flex:0 0 auto;width:auto;max-width:100%}
    .col-xxl-1{flex:0 0 8.33333%;max-width:8.33333%}
    .col-xxl-2{flex:0 0 16.66667%;max-width:16.66667%}
    .col-xxl-3{flex:0 0 25%;max-width:25%}
    .col-xxl-4{flex:0 0 33.33333%;max-width:33.33333%}
    .col-xxl-5{flex:0 0 41.66667%;max-width:41.66667%}
    .col-xxl-6{flex:0 0 50%;max-width:50%}
    .col-xxl-7{flex:0 0 58.33333%;max-width:58.33333%}
    .col-xxl-8{flex:0 0 66.66667%;max-width:66.66667%}
    .col-xxl-9{flex:0 0 75%;max-width:75%}
    .col-xxl-10{flex:0 0 83.33333%;max-width:83.33333%}
    .col-xxl-11{flex:0 0 91.66667%;max-width:91.66667%}
    .col-xxl-12{flex:0 0 100%;max-width:100%}
  }
