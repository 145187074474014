.icon-inline {
  margin-right: 0.3rem; 
  vertical-align: text-bottom;
  height:20px;
  width:20px;
  margin-left:0rem; 
}

.icon-inline-ml {
  margin-left:1rem; 
}

.icon-span {
  margin-left:1rem; 
  visibility:hidden; 
  font-size:0rem;
}

.post {  
  font: var(--font-quote);
  margin-right: 10px;
  font-size: 1.2rem;
}

@media screen and (max-width: 710px) 
{ 
  .post {  
    margin-right: 5px;
    font-size: 1.0rem;
  }
}