.btn-primary {
  background-color: var(--color-blue-grayed);
  border: 2px solid var(--color-blue-grayed);
  color: white;
  font: var(--font-links);
  font-size: 1.1rem;
  transition: background-color 0.7s ease;
  border-radius: 10px 3px 3px 3px;
  padding: 5px 7px 5px 7px;
  margin: 10px 3px 0px 3px;
}

.btn-secondary {
  font-size: 0.9rem;
  border-radius: 8px 3px 3px 3px;
  padding: 2px 5px 2px 5px;
}

.btn-primary:hover {
  background-color: var(--color-blue-medium);
  border-color: var(--color-blue-medium);
  transition: background-color 0.7s ease;
}

.btn-primary:active:focus, .btn-primary.active:focus {
  background-color: var(--color-blue-medium);
  border-color: var(--color-blue-medium);
  color: white;
  font: var(--font-links);
  font-size: 1.1rem;
  outline: none;
  box-shadow: none!important;
}

.btn-secondary:active:focus, .btn-secondary.active:focus {
  font-size: 0.9rem;
}

.btn-primary:focus
{
  background-color: var(--color-blue-grayed);
  border-color: var(--color-blue-medium);
  color: white;
  font: var(--font-links);
  font-size: 1.1rem;
  outline: none;
  box-shadow: none!important;
}

.btn-secondary:focus
{
  font-size: 0.9rem;
}

.btn-primary.active {
  background-color: var(--color-blue-medium)!important;
  border-color: var(--color-blue-medium)!important;
  color: var(--color-yellow-light)!important;
  font: var(--font-links)!important;
  font-size: 1.1rem!important;
  outline: none!important;
  box-shadow: none!important;
  transition: background-color 0.5s ease;
}

.btn-secondary.active {
  font-size: 0.9rem!important;
}

.btn-primary svg {
  stroke: white;
  fill: white;
}

.btn-primary.active svg {
  stroke: #B8B880;
  fill: #B8B880;
}
