.cards-homepage h2 {
  font-size: 1.3rem;
  margin: 0;
  padding-left: 20px;
  margin-top: 0px;
  font: var(--font-header);
}

.cards-homepage .card-header {
  background: var(--color-blue-dark);
  color: var(--color-whitewarm);
  font: var(--font-header);
  border:none;
  white-space: nowrap;
}

.cards-homepage .card-body {
  font: var(--font-text);
  color: black;
}

.cards-homepage .card-body li {
  margin-bottom: 0.25rem;
}

.cards-homepage .card-footer {
  background: transparent;
  border: none;
  text-align:right;
}

.cards-homepage .card:hover > *:not(.card-header):not(button) {
  background-color: var(--color-blue-medium-transp);
  transition: background-color 0.5s ease;
}

.cards-homepage .btn-primary1 {
  background-color: var(--color-blue-medium);
  border-color: var(--color-blue-medium);
  color: var(--color-whitewarm);
  font: var(--font-links);
  font-size: 1.1rem;
}

.card:hover .btn-primary {
  background-color: var(--color-blue-medium);
  border-color: var(--color-blue-medium);
  transition: background-color 0.7s ease;
}


a.svg-cursor:before {
content: "";
display: block;
position: absolute;
background-color: transparen;
cursor: pointer;
width:32px;
height:32px;
}


@media screen and (min-width: 100px) 
{ 
  .card-padding {
    padding: 10px 3px 0px 3px !important;
  }
}
@media screen and (min-width: 1000px) 
{ 
  .card-padding {
    padding: 50px 25px 0px 25px !important;
  }
}