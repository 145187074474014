:root {
  --color-whitecold: #FAFCFF;
  --color-whitewarm: #FAFCF4;
  --color-black: #002038;
  --color-gray: #98B0C0;
  --color-blue-light: #A0D0F0;
  --color-blue-medium: #0080C0;
  --color-blue-grayed: #406080;
  --color-blue-medium-transp: rgba(0, 128, 192, .1);
  --color-blue-dark: #0060A0;
  --color-yellow-light: #F8F8A0;
  --color-yellow-medium: #F8F880;

  --font-links: normal 1.2rem Play,sans-serif;
  --font-header: bold 1.2rem Comfortaa,sans-serif;
  --font-header1: 400 1.3rem Scada,sans-serif;
  --font-text: normal 1rem Roboto,sans-serif;
  --font-text-long: normal 1rem Merriweather,sans-serif;
  --font-quote: normal italic 1rem Montserrat,sans-serif;
}