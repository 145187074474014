.tt {
  border-bottom: dotted var(--color-blue-medium) 2px;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: var(--color-blue-grayed);
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: var(--color-blue-grayed);
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: var(--color-blue-grayed);
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: var(--color-blue-grayed);    
}
.tooltip-inner {
    max-width: 450px; /* ������������ ������ ��������� (�� ��������� 200px) */
    color: var(--color-whitecold);
    background-color: var(--color-blue-grayed);
    border-radius: 0.5rem; /* ������ ���������� ����� */
    text-align: justify;
    text-indent: 1rem;
    font: var(--font-text);
    font-size: 0.9rem;
    padding: 0.5rem;
}

