.news-date {
  font: var(--font-links);
  font-size: 1.2rem;
  font-weight:600;
  color: var(--color-blue-medium);
  background:  white;
  position: absolute; 
  top:-0.95rem; 
  right:2.5rem;  
  border-radius: 10px; 
  border: solid 1px #CCCCCC;
  padding: 3px 15px 5px 15px;
}

.news-text {
  font: var(--font-text);
}

.news-attention {
  color: red;  
}

.news-info {
  color: var(--color-blue-medium);
  text-align: center;  
}

.news-text.news-important, .news-text .news-important{
  font-weight: 600;
}

.news-header-image-left td:nth-child(1),
.news-header-image-right td:nth-child(2) {
  vertical-align: top;
  width:20%;
  min-width:80px;
}

.news-header-image-left td:nth-child(1) {
  text-align:left;
}

.news-header-image-right td:nth-child(2) {
  text-align:right;
}

.news-header-image-none td:nth-child(2) {
  display:none;
}

.news-header-image-left td, .news-header-image-right td, .news-header-image-none td {
  vertical-align: middle;
  font: var(--font-header);
  font-weight:600;
  color: var(--color-blue-dark);
  text-align: center;
}

.news-header-image-left td.news-important, .news-header-image-left td .news-important,
.news-header-image-right td.news-important, .news-header-image-right td .news-important,
.news-header-image-none td.news-important, .news-header-image-none td .news-important {
  font-style: italic;
}

.news-header-image-left td img, .news-header-image-right td img, .news-header-image-none td img {
  width: 100%;
}

@media screen and (min-width: 100px) 
{ 
  .news-text {
    font-size: 1rem;
    line-height:1.4rem;
  }

  .news-info {
    font-size: 1.2rem;
    padding: 5px 0px;
  }

  .news-indent {
    text-indent:1.2rem;
  }

  .news-header-image-left td:nth-child(1) {
    padding: 0px 5px 5px 0px;
  }

  .news-header-image-right td:nth-child(2) {
    padding: 0px 0px 5px 10px;
  }

  .news-header-image-left td, .news-header-image-right td, .news-header-image-none td {
    padding-bottom: 5px;
    font-size: 1.1rem;
  }

  .news-header-image-left td.news-important, .news-header-image-left td .news-important,
  .news-header-image-right td.news-important, .news-header-image-right td .news-important,
  .news-header-image-none td.news-important, .news-header-image-none td .news-important {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1000px) 
{ 
  .news-text {
    font-size: 1rem;
    line-height:1.5rem;
  }

  .news-info {
    font-size: 1.3rem;
    padding: 10px 0px;
  }

  .news-indent {
    text-indent:1.5rem;
  }

  .news-header-image-left td:nth-child(1) {
    padding: 0px 20px 10px 0px;
  }

  .news-header-image-right td:nth-child(2) {
    padding: 0px 0px 10px 20px;
  }

  .news-header-image-left td, .news-header-image-right td, .news-header-image-none td {
    padding-bottom: 10px;
    font-size: 1.4rem;
  }

  .news-header-image-left td.news-important, .news-header-image-left td .news-important,
  .news-header-image-right td.news-important, .news-header-image-right td .news-important,
  .news-header-image-none td.news-important, .news-header-image-none td .news-important {
    font-size: 1.5rem;
  }
}
