footer {
  /* position: sticky; 
  position: -webkit-sticky;
  bottom: 0; */
  margin-top: -100px;
  height: 100px;
  color:white; 
  background-color: var(--color-black);
  padding-top:10px;
  /*white-space: nowrap;*/
}

@media screen and (min-width: 100px) 
{
  footer {
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 1000px) 
{
  footer {
    font-size: 0.8rem;
  }
}

footer a {
  float: left;
  color: var(--color-whitewarm);
  text-align: center;
  padding: 0px 10px;
  text-decoration: underline;
  font-size: 1rem;

}              

footer a:hover {
  color: white;
  text-decoration: none;
}
