.table-price table {
  width: 100%;
}

.table-price th {
  text-align: center; 
  background: var(--color-blue-light);
  border: solid var(--color-blue-medium) 1px;
  font: var(--font-header1);
}

.table-price td {
  border-left: solid var(--color-blue-medium) 1px;
  border-right: solid var(--color-blue-medium) 1px;
  font: var(--font-links);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.table-price tr:nth-last-child(1) td {
  border-bottom: solid var(--color-blue-medium) 1px;
}

.table-price tr:nth-child(even) td {
  background: var(--color-blue-medium-transp);
}

.table-price tr:nth-child(odd) td {
  background: var(--color-whitecold);
}

.table-price-indexes td:nth-child(1) {
  text-align: center;
}

.table-price-indexes td:nth-child(2) {
  text-align: left; 
}

.table-price-indexes td:nth-child(3), .table-price-indexes td:nth-child(4) {
  text-align: right; 
}

.table-price-grand td:nth-child(1), .table-price-grand td:nth-child(2) {
  text-align: center;
}

.table-price-grand td:nth-child(3) {
  text-align: left; 
}

.table-price-grand td:nth-child(4) {
  text-align: right; 
}

@media screen and (min-width: 100px) 
{ 
  .table-price th {
    padding: 0.15rem 0.2rem 0.15rem 0.2rem;
    font-size: 0.9rem;
  }
  .table-price td {
    font-size: 0.9rem;
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
}
@media screen and (min-width: 1000px) 
{ 
  .table-price th {
    padding: 0.25rem 1rem 0.25rem 1rem;
    font-size: 1.1rem;
  }
  .table-price td {
    font-size: 1.1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

  }
}
