.stickers-table {
  border-collapse: collapse;
}

.stickers-area-right {
  position: relative;
  width:100%;  
  margin-left: -18px;
  height:100%
}

.stickers-area-left {
    z-index: 1;
    position: sticky;
    position: -webkit-sticky;
    top: 180px;
}

.stickers-tr {
  cursor:pointer;
  font: var(--font-links);
}

.stickers-tr:focus {
  outline: 0;
}

.sticker-lvl1-left {
    background: var(--color-blue-grayed);
    transition: background-color 0.5s ease;

    color: white;
    padding: 5px 15px 7px 20px;
    margin: 8px 0px 0px 0px;
    border-radius: 10px 0px 0px 0px;
    font-size: 1.2rem;

    white-space: nowrap;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

.sticker-lvl1-right {
    transition: background-color 0.5s ease;
    background: transparent;
    color: transparent;
    padding: 5px 0px 7px 0px;
    margin: 8px 0px 0px -2px;
    width:20px;
    min-width:20px;
    font-size: 1.2rem;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

.sticker-lvl2 {
    background: var(--color-blue-grayed);
    padding: 2px 0px 0px 0px;
    margin: 0px 10px 0px 8px;
    transition: margin 0.5s ease;
}

.sticker-lvl2-left {
    background: var(--color-blue-grayed);
    transition: background-color 0.5s ease;
    color: white;

    padding: 2px 10px 3px 10px;
    font-size: 1.0rem;
    white-space: nowrap;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}


.stickers-tr.active .sticker-lvl1-left {
    background: var(--color-blue-medium);
    color: var(--color-yellow-light);
}

.sticker-lvl1-right-active {
    background: var(--color-blue-grayed);
}

.stickers-tr.active .sticker-lvl1-right {
    background: var(--color-blue-medium);
}

.stickers-table  td {
  padding: 0px;
}

.stickers-table tr:hover .sticker-lvl1-left{
    background: var(--color-blue-medium);
    transition: background-color 0.5s ease;
}
.stickers-table tr:hover .sticker-lvl1-right-active{
    background: var(--color-blue-medium);
    transition: background-color 0.4s ease;
}

.stickers-table td svg {
  stroke:white;
  fill:white;
  /*transform: rotate(0deg);
  transition: transform 0.5s ease;*/
}

.stickers-tr.active svg {
  stroke: #B8B880;
  fill: #B8B880;
}

/*.stickers-table td:hover svg {
  transform: rotate(360deg);
}*/



.sticker-lvl2-left:hover {
    background: var(--color-blue-medium);
    transition: background-color 0.5s ease;
    color: white;
}

.sticker-lvl2-left:focus {
  outline: 0;
}

.sticker-lvl2-left.active {
    background: var(--color-blue-medium);
    color: var(--color-yellow-light);
}