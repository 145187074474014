-webkit-keyframes fade-in {   0% { opacity: 0.1; }   100% { opacity: 1; }   }
@-moz-keyframes    fade-in {   0% { opacity: 0.1; }   100% { opacity: 1; }   }
@-o-keyframes      fade-in {   0% { opacity: 0.1; }   100% { opacity: 1; }   }
@keyframes         fade-in {   0% { opacity: 0.1; }   100% { opacity: 1; }   }
 
html,
body {
  height: 100%;
  background: var(--color-whitewarm);
  font-size: 16px;
  scroll-behavior: smooth;
}
body {
  overflow-y:scroll;
}
#wrap {
  min-height: 100%;
  padding-bottom:0px;
}
.main-content {
  padding-bottom: 100px;
}

.fade-in-content {
  -webkit-animation: fade-in 0.5s ease; /* Safari 4+ */
  -moz-animation: fade-in 0.5s ease; /* Fx 5+ */
  -o-animation: fade-in 0.5s ease; /* Opera 12+ */
  animation: fade-in 0.5s ease; /* IE 10+, Fx 29+ */
}

.router-link {
  text-decoration: none;
  border: none;
}

.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently not supported by any browser */
}

/* Uncomment for NewYear 
.new-year-margin {
  margin-top:-30px;
}
*/

.new-year-padding1 {
  padding-top:45px;
  margin-bottom: 45px;
}

.b-r { border: solid 1px red; }
.b-y { border: solid 1px yellow; }
.b-g { border: solid 1px green; }
